import React from "react"
import { section, listItem, ol } from "./captionList.module.css"

export default function CaptionList({ pageBuilder }) {
  return (
    <div className={section}>
      <ol className={ol}>
        {pageBuilder.map(
          block =>
            block._type === "pageBuilderImage" &&
            block.visibility && (
              <li className={listItem} key={block._key}>
                {block.caption}
              </li>
            )
        )}
      </ol>
    </div>
  )
}
