import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { pageBuilderImage } from "./pageBuilderImage.module.css"

const PageBuilderImage = ({ block }) => {
  const { image, alt, caption, visibility } = block

  return (
    <div>
      {visibility && (
        <GatsbyImage
          className={pageBuilderImage}
          image={image.asset.gatsbyImageData}
          title={caption}
          alt={alt ? alt : caption}
        />
      )}
    </div>
  )
}

export default PageBuilderImage
