import React from "react"
import PortableText from "./portableText"
import {
  section,
  inline,
  h3,
  listItem,
} from "./pageBuilderMasterproject.module.css"

const PageBuilderMasterProject = ({ block }) => {
  const { artwork, body } = block

  return (
    <section className={section}>
      <h3 className={h3}>master project</h3>
      {artwork.map(({ title, media, material, size, year }, index) => (
        <div className={inline} key={index}>
          {title && <p className={listItem}>{title}</p>}
          {media && <p className={listItem}>{media}</p>}
          {material && <p className={listItem}>{material}</p>}
          {size && <p className={listItem}>{size}</p>}
          {year && <p className={listItem}>{year}</p>}
        </div>
      ))}

      <PortableText className={inline} blocks={body} />
    </section>
  )
}

export default PageBuilderMasterProject
