import React, { useEffect } from "react"
import { graphql } from "gatsby"
import PageBuilder from "../components/pageBuilder"
import Seo from "../components/seo"
/* import { SRLWrapper } from "simple-react-lightbox" */
import localize from "../components/localize"
import Selectors from "../components/selectors"
import ArtistProfile from "../components/artistProfile"
import CaptionList from "../components/captionList"
import { accordionContent, heading } from "./pageArtist.module.css"

const PageArtist = ({ data, langKey, pathname, onClick, location }) => {
  const { sanityArtist, sanityExhibition, allSanityArtist } = data
  const { firstName, lastName, pageBuilder, _rawPageBuilder } = sanityArtist
  const state = location.state || {}
  const { fromNav } = state

  const title = firstName + " " + lastName

  /* const options = {
    settings: {
      disablePanzoom: true,
    },
    caption: {},
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showFullscreenButton: false,
    },
    thumbnails: {},
    progressBar: {},
  } */

  useEffect(() => {
    if (fromNav === true) {
      onClick(1)
    }
  }, [])

  return (
    <div className={accordionContent}>
      <Seo dynTitle={title} />
      <h2 className={heading}>
        {firstName} {lastName}
      </h2>
      <Selectors
        langKey={langKey}
        pathname={pathname}
        exName={sanityExhibition.exName}
        id={sanityArtist.id}
        edges={allSanityArtist.edges}
        type="artist"
      />
      <ArtistProfile langKey={langKey} sanityArtist={sanityArtist} />
      <PageBuilder
        pageBuilder={pageBuilder}
        _rawPageBuilder={_rawPageBuilder}
      />
      <CaptionList pageBuilder={pageBuilder} />
    </div>
  )
}

export default localize(PageArtist)

export const query = graphql`
  query ($id: String!, $idExhibition: String!) {
    sanityArtist(id: { eq: $id }) {
      id
      firstName
      lastName
      mailAddress
      url
      field {
        fieldName {
          _type
          de
          en
        }
      }
      professor {
        title {
          _type
          de
          en
        }
        firstName
        lastName
      }

      ...PageBuilderFragment
    }
    sanityExhibition(id: { eq: $idExhibition }) {
      ...SanityExhibitionFragment
    }
    allSanityArtist(
      filter: {
        participance: { eq: true }
        exhibition: { id: { eq: $idExhibition } }
      }
      sort: { fields: lastName, order: ASC }
    ) {
      ...SanityArtistConnectionFragment
    }
    allSanityExhibition(sort: { fields: exName, order: ASC }) {
      ...SanityExhibitionConnectionFragment
    }
    sanitySiteSettings {
      ...SanitySiteSettingsFragment
    }
  }
`
