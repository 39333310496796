import React from "react"
import { listItem, section } from "../components/artistProfile.module.css"

const ArtistProfile = ({ langKey, sanityArtist }) => {
  const { professor, field, url, mailAddress } = sanityArtist

  const contact = langKey === "de" ? "Kontakt" : "Contact"

  return (
    <ul className={section}>
      <li className={listItem}>
        {professor.title} {professor.firstName} {professor.lastName}
      </li>
      <li className={listItem}>{field.fieldName}</li>
      {url && (
        <li className={listItem}>
          <a rel="noreferrer" href={url} target="_blank">
            Website
          </a>
        </li>
      )}
      {mailAddress && (
        <li className={listItem}>
          <a href={"mailto:" + mailAddress}>{contact}</a>
        </li>
      )}
    </ul>
  )
}

export default ArtistProfile
