import React from "react"
import PortableText from "./portableText"
import { section, h3, inline } from "./pageBuilderArtistStatement.module.css"

const PageBuilderArtistStatement = ({ raw }) => {
  return (
    <section className={section}>
      <h3 className={h3}>artist statement</h3>
      <PortableText className={inline} blocks={raw.body} />
    </section>
  )
}

export default PageBuilderArtistStatement
